<template>
<div>
    <h2 class="cart-header-sec mt-4 mt-md-5 pb-2 pb-md-3 pt-2">Номер телефона</h2>
    <div class="cart-phone-number">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3 cart-phone-number__inputs">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <the-mask mask="7 (EEE) EEE EE EE" autocomplete="false" :tokens="hexTokens" placeholder="7 (XXX) XXX XX XX" v-model.trim="phone" class="form-control" id="phone" />
                        </div>
                       <div class="col-12">
                        <div class="invalid-feedback d-block">
                            {{showError}}
                        </div>
                       </div>
                        <div class="col-12 d-md-none mb-3 mt-1">
                            <button class="btn-oval w-100" @click="sendSms()">
                                Получить код
                            </button>
                            <button class="btn-oval d-none d-md-inline-block" @click="sendCode()">
                                Войти
                            </button>
                        </div>
                        <div class="col-12" v-if="hideSendCode">
                            <the-mask mask="####" v-model.trim="code" class="form-control" placeholder="Код из SMS" autocomplete="false"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <button v-if="hideSendNumber" class="btn-oval d-none d-md-inline-block" @click="sendSms()">
                    Получить код
                </button>
                <button v-if="hideSendCode" class="btn-oval d-none d-md-inline-block fixw" @click="sendCode()">
                    Войти
                </button>
                <p>
                    Нажимая кнопку “Войти”, вы соглашаетесь<br>
                    <span>со сбором и обработкой персональных данных и пользовательским соглашением</span>
                </p>
            </div>
           
        </div>
    </div>
</div>
</template>

<script>
import {
    TheMask
} from "vue-the-mask";
export default {
    data() {
        return {
            countDown: null,
            phone: null,
            code: null,
            hideSendNumber: true,
            hideSendCode: false,
            showError: '',
            hexTokens: {
                F: {
                    pattern: /[7-8]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
                E: {
                    pattern: /[0-9]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
            },

            // allowedDate: '2022-05-27'
        };
    },
    components: {

        TheMask,

    },
    methods: {
        async sendSms() {
            try {
                await this.$store.dispatch("actionSendSms", this.phone);
                await this.$store.dispatch('actionGetCart');
                this.showError = ''
               // await this.$store.dispatch("actionGetBalance");
                // this.showPhone = false;
                // this.showSms = true;
                this.countDown = 10
                this.hideSendNumber = false,
                    this.hideSendCode = true,
                    this.countDownTimer();
            } catch (error) {
                this.showError = error.response.data.message;
            }
        },
        async sendCode() {
            const data = {
                code: this.code,
                phone: `7${this.phone}`,
            };
            try {
                const res = await this.$store.dispatch("actionRegistration", data);
                await this.$store.dispatch('actionGetCart');
                await this.$store.dispatch("actionGetBalance");
                this.showText = res.data.message;
                this.$router.go(this.$router.currentRoute)
                //alert(this.showText)
            } catch (error) {
                this.showError = error.response.data.message;
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
    },

}
</script>

<style lang="scss" scoped>
.cart-phone-number {
    input {
        border: none;
        border-radius: 73px;
        height: 57px;
        font-family: Montserrat;

        color: #000000;
        padding-left: 29px;

        font-weight: 600;
        font-size: 16px;

        &::placeholder {
            font-weight: 400;
        }
    }

    p {
        margin-top: 10px;
        font-family: Montserrat;

        font-size: 12px;
        line-height: 142.69%;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        span {
            color: $orange;
        }
    }
}
.fixw{
    width: 188px;
}
</style>
